import httpService from '../../../helpers/http.service'

export default {

    liquidation(id = null, params = {}) {
        return httpService.get(`/my-pays/liquidations/commissions${id ? '/' + id : '' }`, {params})
    },
    pdfLiquidation(id){
        return httpService.get(`/my-pays/liquidations/reports/${id}/export`)
    },

}
